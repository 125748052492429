.bar {
    height: 0;
    transition: 1s;
}
.bar.loaded {
    height: 51px;
}

.logo {
    margin-top: -160px;
    transition: 0.7s;
}
.logo.loaded {
    margin-top: 0;
}

.email-tools {
    margin-top: -118px;
    transition: 0.7s;
}
.email-tools.loaded {
    margin-top: 0;
}

.email-action {
    font-size: 16px;
    transition: 0.2s;
}
.email-action:hover {
    color: #999;
}

.thumbnail-contain {
    box-shadow: 0 0 20px #666;
    transition: 0.2s;
}
.thumbnail-contain:hover {
    box-shadow: 0 0 20px #333;
}

.download-icon {
    color: #fff;
    transition: 0.2s;
}
.download-icon:hover {
    color: #999;
}

.button {
    align-items: center;
    background-color: #0065a3;
    border-radius: 30px;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-size: 18px;
    font-weight: 700;
    height: 62px;
    justify-content: center;
    width: 241px;
}
.single-card-contain {
    background-color: #fff;
    box-shadow: 0 0 20px #666;
    max-width: 75%;
    overflow: hidden;
    padding: 5rem;
    width: 800px;
}
.single-card-label {
    color: #808080;
    font-size: 26px;
    font-weight: 400;
    padding-bottom: 27px;
}
.single-card-title {
    color: #808080;
    font-size: 40px;
    font-weight: 800;
    padding-bottom: 27px;
}
.button-contain {
    display: flex;
    justify-content: flex-end;
    margin-top: 27px;
}
@media only screen and (max-width: 600px) {
    .button-contain {
        justify-content: center;
    }
}
