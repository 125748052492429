* {
    box-sizing: border-box;
}

html,
body,
#root,
.App {
    height: 100%;
    width: 100%;
}

.App {
    align-items: center;
    display: flex;
    justify-content: center;
}

.App-background {
    background-size: cover;
    background-position: center center;
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: -1;
}

.App-background.loaded {
    opacity: 1;
    transition: 2s;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background: none;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
